<template>
  <div
    style="padding-top:50px;padding-bottom:50px;"
  >
    <div style="justify-content:left; background-color:#fff;">
      <b-row class="auth-inner m-0">
        <b-col
          lg="12"
          class="d-flex align-items-center-disabble auth-bg px-2 p-lg-5"
          style="/*min-width:80vw; !important;*/"
        >
          <b-col
            sm="12"
            md="12"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <div
              style="text-align:left; margin-top:10px; margin-bottom:10px;"
            >
              <b-img
                style="width:150px;"
                :src="require(`@/assets/images/partner/${partner}/logo/logo.png`)"
              />
              <br><br>
            </div>
            <b-row>
              <b-col>
                <div v-html="imprintHtml" />
              </b-col>
              <b-col
                sm="12"
                md="12"
                lg="12"
              >
                <br><br>
                <b-button
                  :variant="`outline-${partnerData.colorScheme}`"
                  size="sm"
                  @click="$router.go(-1)"
                  pill
                >
                  Schließen
                </b-button>
                <br><br>
              </b-col>
            </b-row>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { localize } from 'vee-validate'
import {
  BRow, BCol, BImg, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store/index'
import homeStoreModule from './homeStoreModule'

localize('de')
export default {
  components: {
    // VuexyLogo,
    BRow,
    BImg,
    BCol,
    BButton,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/register-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    const APP_HOME_STORE_MODULE_NAME = 'app-home'

    // Register module
    if (!store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.registerModule(APP_HOME_STORE_MODULE_NAME, homeStoreModule)

    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`

    //  document.title = partnerData.value.htmlPageTitle
    //  document.querySelector("link[rel*='icon']").href = `favicon_${partnerData.value.key}.ico`
    const appIdElem = document.getElementById('app')
    let backgroundImage = ''
    if (typeof (window.$cookies.get('partner')) !== 'undefined') {
      backgroundImage = require(`@/assets/images/partner/${partnerData.value.key}/backgorund/auth.jpg`)
      appIdElem.style.backgroundImage = `url('${backgroundImage}')`
    }

    const imprintHtml = ref('')

    store
      .dispatch(`${APP_HOME_STORE_MODULE_NAME}/getPartnerImprint`, { partnerKey: partnerData.value.key })
      .then(response => {
        imprintHtml.value = response.data
      })
      .catch(error => {
        console.log(error)
      })

    return {
      partner,
      partnerData,
      getHrefStyle,
      imprintHtml,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style>
.imprintTable {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid;
}

.imprintTable td, .imprintTable th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

</style>
